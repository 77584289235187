import { Translate } from "../../core/translate";
import * as React from "react";
import { BASEMAP_TYPES, BasemapModel } from "../../models/basemap";
import { Bus } from "../../core/bus";
import {
  AjaxResponse,
  AjaxResponseT,
  isAjaxOkAny,
} from "../../core/ajaxresponse";
import _ from "lodash";

interface Props {
  basemap: BasemapModel;
  translate: Translate;
  onDelete: () => void;
}

interface State {
  basemap: BasemapModel;
  saved: boolean;
}

export class BasemapDetail extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = { basemap: props.basemap, saved: true };

    this.onChange = this.onChange.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.save = _.debounce(this.save, 100).bind(this);
    this.cbSave = this.cbSave.bind(this);
    this.delete = this.delete.bind(this);
    this.cbDelete = this.cbDelete.bind(this);
  }

  private onChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    field: string
  ) {
    this.setState((prevState) => {
      const basemap = prevState.basemap;
      const value = e?.target?.value;
      basemap.set_value(field, value);
      return { ...prevState, basemap };
    }, this.save);
  }

  private onChangeCheckbox(e: any, field: string) {
    this.setState((prevState) => {
      const basemap = prevState.basemap;

      if (field === "show_layer") {
        basemap.set_value(field, !basemap.show_layer);
      } else if (field === "use_transparancy") {
        basemap.set_value(field, !basemap.use_transparancy);
      }

      return { ...prevState, basemap };
    }, this.save);
  }

  private save() {
    const bus = Bus.getInstance();
    const response = new AjaxResponse(bus, [], this.cbSave);
    this.state.basemap.save(response);
  }

  private cbSave(response: AjaxResponseT) {
    this.setState({ saved: isAjaxOkAny(response) });
  }

  private delete() {
    const trAreYouSure = this.props.translate.go("Are you sure?");

    if (window.confirm(trAreYouSure)) {
      const bus = Bus.getInstance();
      const response = new AjaxResponse(bus, [], this.cbDelete);
      this.state.basemap.del(response);
    }
  }

  private cbDelete(response: AjaxResponseT) {
    console.debug("BasemapDetail.cbDelete", response);
    if (isAjaxOkAny(response)) {
      console.debug("BasemapDetail.cbDelete is ok", response);
      this.props.onDelete();
    }
  }

  render() {
    const translate = this.props.translate;
    const trName = translate.go("Name");
    const trType = translate.go("Type");
    const trShowLayer = translate.go("Show Layer");
    const trUseTransparancy = translate.go("Use Transparancy");
    const trWfsUrl = translate.go("Wfs Url");
    const trWfsLayerName = translate.go("Wfs Layer Name");
    const trWmsUrl = translate.go("Wms Url");
    const trWmsLayerName = translate.go("Wms Layer Name");
    const trWmtsUrl = translate.go("Wmts Url");
    const trWmtsLayerName = translate.go("Wmts Layer Name");
    const trSortOrder = translate.go("Sort Order");
    const trDelete = translate.go("Delete");
    const trNotSaved = translate.go("Not saved, check the input fields");

    const basemap = this.state.basemap;

    const didSave = this.state.saved ? "saved" : "notsaved";

    return (
      <div className={didSave}>
        <h4>
          {basemap.name ? basemap.name : <span>&nbsp;</span>}{" "}
          <button onClick={(e) => this.delete()}>{trDelete}</button>
        </h4>
        <form>
          <label htmlFor={`basemap-detail-name-${basemap.id}`}>{trName}</label>
          <input
            id={`basemap-detail-name-${basemap.id}`}
            type={"text"}
            value={basemap.name}
            onChange={(e) => this.onChange(e, "name")}
          />
          <br />

          <label htmlFor={`basemap-detail-type-${basemap.id}`}>{trType}</label>
          <select
            id={`basemap-detail-type-${basemap.id}`}
            value={basemap.type}
            onChange={(e) => this.onChange(e, "type")}
          >
            {BASEMAP_TYPES.map((type) => (
              <option value={type}>{type}</option>
            ))}
          </select>
          <br />

          <label htmlFor={`basemap-detail-show_layer-${basemap.id}`}>
            {trShowLayer}
          </label>
          <input
            id={`basemap-detail-show_layer-${basemap.id}`}
            type={"checkbox"}
            checked={basemap.show_layer}
            onChange={(e) => this.onChangeCheckbox(e, "show_layer")}
          />
          <br />

          <label htmlFor={`basemap-detail-use_transparancy-${basemap.id}`}>
            {trUseTransparancy}
          </label>
          <input
            id={`basemap-detail-use_transparancy-${basemap.id}`}
            type={"checkbox"}
            checked={basemap.use_transparancy}
            onChange={(e) => this.onChangeCheckbox(e, "use_transparancy")}
          />
          <br />

          {basemap.type === "wfs" && (
            <>
              <label htmlFor={`basemap-detail-wfs_url-${basemap.id}`}>
                {trWfsUrl}
              </label>
              <input
                id={`basemap-detail-wfs_url-${basemap.id}`}
                type={"text"}
                value={basemap.wfs_url}
                onChange={(e) => this.onChange(e, "wfs_url")}
              />
              <br />

              <label htmlFor={`basemap-detail-wfs_layer_name-${basemap.id}`}>
                {trWfsLayerName}
              </label>
              <input
                id={`basemap-detail-wfs_layer_name-${basemap.id}`}
                type={"text"}
                value={basemap.wfs_layer_name}
                onChange={(e) => this.onChange(e, "wfs_layer_name")}
              />
              <br />
            </>
          )}

          {basemap.type === "wms" && (
            <>
              <label htmlFor={`basemap-detail-wms_url-${basemap.id}`}>
                {trWmsUrl}
              </label>
              <input
                id={`basemap-detail-wms_url-${basemap.id}`}
                type={"text"}
                value={basemap.wms_url}
                onChange={(e) => this.onChange(e, "wms_url")}
              />
              <br />

              <label htmlFor={`basemap-detail-wms_layer_name-${basemap.id}`}>
                {trWmsLayerName}
              </label>
              <input
                id={`basemap-detail-wms_layer_name-${basemap.id}`}
                type={"text"}
                value={basemap.wms_layer_name}
                onChange={(e) => this.onChange(e, "wms_layer_name")}
              />
              <br />
            </>
          )}

          {basemap.type === "wmts" && (
            <>
              <label htmlFor={`basemap-detail-wmts_url-${basemap.id}`}>
                {trWmtsUrl}
              </label>
              <input
                id={`basemap-detail-wmts_url-${basemap.id}`}
                type={"text"}
                value={basemap.wmts_url}
                onChange={(e) => this.onChange(e, "wmts_url")}
              />
              <br />

              <label htmlFor={`basemap-detail-wmts_layer_name-${basemap.id}`}>
                {trWmtsLayerName}
              </label>
              <input
                id={`basemap-detail-wmts_layer_name-${basemap.id}`}
                type={"text"}
                value={basemap.wmts_layer_name}
                onChange={(e) => this.onChange(e, "wmts_layer_name")}
              />
              <br />
            </>
          )}

          <label htmlFor={`basemap-detail-sort_order-${basemap.id}`}>
            {trSortOrder}
          </label>
          <input
            id={`basemap-detail-sort_order-${basemap.id}`}
            type={"number"}
            value={basemap.sort_order}
            onChange={(e) => this.onChange(e, "sort_order")}
          />

          <div className={"show-if-not-saved"}>{trNotSaved}</div>
        </form>
      </div>
    );
  }
}
