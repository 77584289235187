import * as ReactDOM from "react-dom";
import * as React from "react";

import { PlanViewer } from "../planviewer";
import { CustomerModel, CustomerConnector } from "../../models/customer";
import { Bus } from "../../core/bus";
import { AjaxResponse, AjaxResponseT, isAjaxOk } from "../../core/ajaxresponse";
import { Translate } from "../../core/translate";
import { CustomerDetail } from "./CustomerDetail";

interface CustomerListProps {
  planviewer: PlanViewer;
  translate: Translate;
}

export interface CustomerListState {
  customers: CustomerModel[];
}

export class CustomerList extends React.Component<
  CustomerListProps,
  CustomerListState
> {
  constructor(props: CustomerListProps) {
    super(props);
    this.state = { customers: [] };
    this.cbGetCustomers = this.cbGetCustomers.bind(this);
    this.selectCustomer = this.selectCustomer.bind(this);
    this.addCustomer = this.addCustomer.bind(this);
    this.deleteCustomer = this.deleteCustomer.bind(this);
    this.loadCustomers = this.loadCustomers.bind(this);
  }

  componentDidMount(): void {
    this.loadCustomers();
  }

  loadCustomers(): void {
    const bus = Bus.getInstance();
    const response = new AjaxResponse(bus, [], this.cbGetCustomers);
    new CustomerConnector().list(response);
  }

  private cbGetCustomers(response: AjaxResponseT): void {
    if (isAjaxOk(response)) {
      if (
        "customer" in response.data &&
        Array.isArray(response.data.customer)
      ) {
        const customers: CustomerModel[] = [];
        for (const item of response.data.customer) {
          customers.push(CustomerModel.create(item));
        }
        this.setState((prevState) => {
          return {
            ...prevState,
            customers,
          };
        });
      }
    }
  }

  render(): JSX.Element {
    const translate = this.props.translate;

    const trCustomerList = translate.go("Customer list");
    const trCustomerAdd = translate.go("Add a customer");
    const trCustomerDelete = translate.go("Remove a customer");

    return (
      <div className={"adminpanel__contents"}>
        <h2 className={"title"}>{trCustomerList}</h2>
        <button onClick={this.addCustomer}>{trCustomerAdd}</button>
        <table>
          <tbody>
            {this.state.customers.map((customer) => (
              <tr key={customer.id}>
                <td>
                  <button data-id={customer.id} onClick={this.selectCustomer}>
                    {customer.name}
                  </button>
                </td>
                <td>
                  <button
                    data-id={customer.id}
                    data-name={customer.name}
                    className={"danger"}
                    onClick={this.deleteCustomer}
                  >
                    {trCustomerDelete}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  deleteCustomer(event: React.MouseEvent<HTMLButtonElement>): void {
    const trAreYouSure = this.props.translate.go(
      "Are you sure you want to delete this customer?"
    );
    const customerId = parseInt((event.target as any).dataset.id, 10);
    const customerName = (event.target as any).dataset.name;
    if (customerId < 1) {
      return;
    }
    const message = `${trAreYouSure}\n(${customerName})`;
    const result = window.confirm(message);
    if (result) {
      const bus = Bus.getInstance();
      const response = new AjaxResponse(bus, [], this.loadCustomers);
      new CustomerConnector().del(customerId, response);
    }
  }

  private addCustomer(_event: React.MouseEvent<HTMLButtonElement>): void {
    this.changeCustomer(0);
  }

  selectCustomer(event: React.MouseEvent<HTMLButtonElement>): void {
    const customerId = parseInt((event.target as any).dataset.id, 10);
    if (customerId < 0) {
      return;
    }
    this.changeCustomer(customerId);
  }

  changeCustomer(customerId: number): void {
    ReactDOM.render(
      <CustomerDetail
        customerId={customerId}
        customerList={this}
        planviewer={this.props.planviewer}
        translate={this.props.translate}
      />,
      document.getElementById("adminpanel")
    );
  }
}
