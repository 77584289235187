import * as React from "react";

import { PlanViewer } from "../planviewer";
import { CustomerList } from "./CustomerList";
import { Translate } from "../../core/translate";

interface AdminPanelProps {
  planviewer: PlanViewer;
  translate: Translate;
}

export class AdminPanel extends React.Component<AdminPanelProps, unknown> {
  constructor(props: AdminPanelProps) {
    super(props);
  }

  render(): JSX.Element {
    return (
      <div className={"adminpanel__contents"}>
        <CustomerList
          planviewer={this.props.planviewer}
          translate={this.props.translate}
        />
      </div>
    );
  }
}
