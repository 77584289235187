import {
  BASE_URL,
  Connector,
  getCustomerPanelAjaxTokenHeaders,
} from "../core/connector";
import { AjaxResponse } from "../core/ajaxresponse";
import { Ajax } from "../core/ajax";
import { CustomerModel } from "./customer";
import { Model } from "../core/model";

export class OauthConnector extends Connector {
  customerId: number;

  constructor(customerId: number) {
    super(`${BASE_URL}/customer/${customerId}/oauth`);
    this.customerId = customerId;
  }
}

export interface IOauth {
  id: undefined | number;
  client_id: string;
  secret: string;
  auth_url: string;
  accesstoken_url: string;
  resource_url: string;
  customer: CustomerModel;
  scope: string;
  api_id?: string;
}

export class OauthModel extends Model implements IOauth {
  id: undefined | number;
  client_id: string;
  secret: string;
  auth_url: string;
  accesstoken_url: string;
  resource_url: string;
  scope: string;
  customer: CustomerModel;
  api_id?: string;

  constructor(
    id: number | undefined,
    client_id: string,
    secret: string,
    auth_url: string,
    accesstoken_url: string,
    resource_url: string,
    scope: string,
    customer: CustomerModel,
    api_id?: string
  ) {
    if (
      customer.id === undefined ||
      Number.isNaN(customer.id) ||
      customer.id < 1
    ) {
      throw new Error("invalid customer number");
    }
    super(new OauthConnector(customer.id), "id", "Oauth", [
      "id",
      "client_id",
      "secret",
      "auth_url",
      "accesstoken_url",
      "resource_url",
      "scope",
      "api_id",
    ]);
    this.id = id;
    this.client_id = client_id;
    this.secret = secret;
    this.auth_url = auth_url;
    this.accesstoken_url = accesstoken_url;
    this.resource_url = resource_url;
    this.scope = scope;
    this.customer = customer;
    this.api_id = api_id;
  }

  get props(): Record<string, unknown> {
    const props = super.props;
    props.customer = this.customer.getUrl().replace(/\/$/, "");
    if (props.id === 0) {
      props.id = undefined;
    }
    // We don't want to send/change that field
    delete props.api_id;
    return props;
  }

  public static default(customer: CustomerModel): OauthModel {
    return new OauthModel(
      0,
      "123456",
      "secr3t",
      "https://auth0.tenant.domain.nl/authorize",
      "https://auth0.tenant.domain.nl/oauth/token",
      "https://example.com/resource.json",
      "",
      customer,
      "abcabcabc0"
    );
  }

  public static create(props: IOauth): OauthModel {
    const oauth = new OauthModel(
      props.id,
      props.client_id,
      props.secret,
      props.auth_url,
      props.accesstoken_url,
      props.resource_url,
      props.scope,
      props.customer,
      props.api_id
    );
    return oauth;
  }

  public set_value(field: string, value: unknown): void {
    if (field === "secret" && typeof value === "string") {
      this.secret = value;
    } else if (field === "client_id" && typeof value === "string") {
      this.client_id = value;
    } else if (field === "auth_url" && typeof value === "string") {
      this.auth_url = value;
    } else if (field === "accesstoken_url" && typeof value === "string") {
      this.accesstoken_url = value;
    } else if (field === "resource_url" && typeof value === "string") {
      this.resource_url = value;
    } else if (field === "scope" && typeof value === "string") {
      this.scope = value;
    } else if (field === "id" && (this.id === undefined || this.id === 0)) {
      this.id = value as number;
    } else if (field === "api_id" && typeof value === "string") {
      // this will be generated by the server, together with the api_key
    } else {
      // can't change customer field
    }
  }

  public generateNewApiKey(ajaxResponse: AjaxResponse): void {
    let url = this.connector.generateUrl("read", this.id, true)[0];
    url = `${url}generate_new_api_key`;
    const method = "GET";
    const headers = getCustomerPanelAjaxTokenHeaders();
    const ajax = new Ajax(url, ajaxResponse, method, headers);
    ajax.exec();
  }
}
