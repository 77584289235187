import { CustomerConnector, CustomerModel } from "../../models/customer";
import { OauthConnector, OauthModel } from "../../models/oauth";
import { CustomerDetail } from "./CustomerDetail";
import { CustomerSldConnector } from "../../models/sld";
import { Translate } from "../../core/translate";
import { Bus } from "../../core/bus";
import {
  AjaxResponse,
  AjaxResponseT,
  isAjaxOk,
  OkKey,
} from "../../core/ajaxresponse";

import * as React from "react";

export interface CustomerSectionState {
  customer: CustomerModel;
  hasSld: boolean;
}

export interface CustomerSectionProps {
  customer: CustomerModel;
  customerDetail: CustomerDetail;
  translate: Translate;
}

export class CustomerSection extends React.Component<
  CustomerSectionProps,
  CustomerSectionState
> {
  constructor(props: CustomerSectionProps) {
    super(props);
    this.state = { customer: props.customer, hasSld: false };

    this.cbGetCustomer = this.cbGetCustomer.bind(this);
    this.cbSldSaved = this.cbSldSaved.bind(this);
    this.cbSldRead = this.cbSldRead.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.cbCreatedCustomer = this.cbCreatedCustomer.bind(this);
    this.cbCreatedOauth = this.cbCreatedOauth.bind(this);
    this.cbSaveNew = this.cbSaveNew.bind(this);
  }

  componentDidMount(): void {
    if (this.props.customer.id !== undefined && this.props.customer.id !== 0) {
      const bus = Bus.getInstance();
      const conn = new CustomerSldConnector(this.props.customer.id);
      const response = new AjaxResponse(bus, [], this.cbSldRead);
      conn.read(this.props.customer.id, response);
    }
  }

  cbSldRead(response: AjaxResponseT): void {
    const hasSld = response.status === OkKey;
    this.setState((oldState) => {
      return { ...oldState, hasSld: hasSld };
    });
  }

  cbSldSaved(response: AjaxResponseT): void {
    const trNotSaved = this.props.translate.go(
      "Not saved, check the input fields"
    );
    if (response.status === OkKey) {
      this.setState((oldState) => {
        return { ...oldState, hasSld: true };
      });
    } else {
      console.error(response);
      alert(trNotSaved);
    }
  }

  handleFileChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const fileObjs = event?.target?.files;
    if (fileObjs === null || fileObjs.length < 1) {
      return;
    }
    const fileObj = fileObjs[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const content = event.target?.result;
      if (
        content !== null &&
        content !== undefined &&
        this.props.customer.id !== undefined &&
        this.props.customer.id !== 0
      ) {
        const data = {
          file: { name: fileObj.name, content: btoa(content as string) },
        };
        const bus = Bus.getInstance();
        const conn = new CustomerSldConnector(this.props.customer.id);
        const response = new AjaxResponse(bus, [], this.cbSldSaved);
        conn.save(this.props.customer.id, data, response);
      }
    };
    reader.readAsText(fileObj, "utf-8");
  }

  handleCustomerChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const newMode =
      this.state.customer.id === undefined || this.state.customer.id === 0;
    this.setState((oldState) => {
      const customer = oldState.customer;

      // currently we only update the name field
      const value = event?.target?.value;
      customer.set_value("name", value);

      const bus = Bus.getInstance();
      if (!newMode) {
        const response = new AjaxResponse(bus, [], this.cbGetCustomer);
        new CustomerConnector().save(customer.pk, customer.props, response);
      }
      return { ...oldState, customer };
    });
  }

  private cbCreatedOauth(_response: AjaxResponseT): void {
    this.props.customerDetail.changeCustomer(this.state.customer);
  }

  private cbCreatedCustomer(response: AjaxResponseT): void {
    const trNotSaved = this.props.translate.go(
      "Not saved, check the input fields"
    );

    if (isAjaxOk(response) && "customer" in response.data) {
      const rcustomer = response.data.customer as CustomerModel;
      const ncustomer = CustomerModel.create(rcustomer);
      this.setState((oldState) => {
        const customer = oldState.customer;
        for (const k in response.data.customer as Record<string, any>) {
          if (Object.prototype.hasOwnProperty.call(response.data.customer, k)) {
            customer.set_value(k, (response.data.customer as any)[k]);
          }
        }
        return { ...oldState, customer };
      });

      if (ncustomer.id !== undefined && ncustomer.id !== 0) {
        const bus = Bus.getInstance();
        const oauth = OauthModel.default(ncustomer);
        const response = new AjaxResponse(bus, [], this.cbCreatedOauth);
        new OauthConnector(ncustomer.id).create(oauth.props, response);
      }
    } else {
      alert(trNotSaved);
      console.error(response);
    }
  }

  cbSaveNew(_event: React.MouseEvent<HTMLButtonElement>): void {
    _event.preventDefault();
    const bus = Bus.getInstance();
    const response = new AjaxResponse(bus, [], this.cbCreatedCustomer);
    new CustomerConnector().create(this.state.customer.props, response);
  }

  private cbGetCustomer(response: AjaxResponseT): void {
    const trNotSaved = this.props.translate.go(
      "Not saved, check the input fields"
    );
    if (isAjaxOk(response) && "customer" in response.data) {
      const rcustomer = response.data.customer as CustomerModel;
      const customer = CustomerModel.create(rcustomer);
      this.props.customerDetail.changeCustomer(customer);
    } else {
      console.error(response);
      alert(trNotSaved);
    }
  }

  render(): JSX.Element {
    const translate = this.props.translate;

    const trName = translate.go("Name");
    const trBase = translate.go("Base settings");
    const trSLDFile = translate.go("SLD File");
    const trSave = translate.go("Save");
    const advancedSettings = () => {
      const newMode =
        this.state.customer.id === undefined || this.state.customer.id === 0;
      if (newMode) {
        return (
          <div>
            <button onClick={this.cbSaveNew}>{trSave}</button>
          </div>
        );
      } else {
        return (
          <div>
            <label htmlFor={"sld_file"} className={"fieldLabel"}>
              {trSLDFile}
            </label>
            <span>
              <input
                type={"checkbox"}
                disabled={true}
                checked={this.state.hasSld}
              />
              <br />
              <input
                type={"file"}
                name={"sld_file"}
                id={"sld_file"}
                value={""}
                placeholder={"Select file"}
                multiple={false}
                onChange={this.handleFileChange}
              />
            </span>
          </div>
        );
      }
    };

    return (
      <div>
        <h3 className={"subTitle"}>{trBase}</h3>
        <form>
          <input type={"hidden"} name={"id"} value={this.state.customer.pk} />

          <label htmlFor={"name"} className={"fieldLabel"}>
            {trName}
          </label>
          <input
            type={"text"}
            name={"name"}
            id={"name"}
            value={this.state.customer.name}
            placeholder={"Gemeente Lutjebroek"}
            onChange={this.handleCustomerChange}
            className={"fieldInput"}
          />

          <br />
          {advancedSettings()}
        </form>
      </div>
    );
  }
}
