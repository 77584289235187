import { BASE_URL, Connector } from "../core/connector";
import { Model } from "../core/model";
import { CustomerModel } from "./customer";

export class BasemapConnector extends Connector {
  customerId: number;

  constructor(customerId: number) {
    super(`${BASE_URL}/customer/${customerId}/customer_base_layer`);
    this.customerId = customerId;
  }
}

export interface IBasemap {
  id: undefined | number;
  name: string;
  type: BasemapType;
  customer: CustomerModel;
  show_layer: boolean;
  use_transparancy: boolean;
  wfs_url: string;
  wfs_layer_name: string;
  wms_url: string;
  wms_layer_name: string;
  wmts_url: string;
  wmts_layer_name: string;
  sort_order: number;
}

export const BASEMAP_TYPES: ReadonlyArray<string> = ["empty", "osm", "aerial", "bag", "wfs", "wmts", "wms"]
type BasemapType = "osm" | "aerial" | "empty" | "wfs" | "wmts" | "wms" | "bag";

function isBasemapType(value: any): value is BasemapType {
  return BASEMAP_TYPES.indexOf(value) !== -1;
}

export class BasemapModel extends Model implements IBasemap {
  id: undefined | number;
  name: string;
  type: BasemapType;
  customer: CustomerModel;
  show_layer: boolean;
  use_transparancy: boolean;
  wfs_url: string;
  wfs_layer_name: string;
  wms_url: string;
  wms_layer_name: string;
  wmts_url: string;
  wmts_layer_name: string;
  sort_order: number;

  constructor(
    id: number | undefined,
    name: string,
    type: BasemapType,
    customer: CustomerModel,
    show_layer: boolean,
    use_transparancy: boolean,
    wfs_url: string,
    wfs_layer_name: string,
    wms_url: string,
    wms_layer_name: string,
    wmts_url: string,
    wmts_layer_name: string,
    sort_order: number
  ) {
    super(new BasemapConnector(customer.id!), "id", "Basemap", [
      "id",
      "name",
      "type",
      "show_layer",
      "use_transparancy",
      "wfs_url",
      "wfs_layer_name",
      "wms_url",
      "wms_layer_name",
      "wmts_url",
      "wmts_layer_name",
      "sort_order",
    ]);
    this.id = id;
    this.name = name;
    this.type = type;
    this.customer = customer;
    this.show_layer = show_layer;
    this.use_transparancy = use_transparancy;
    this.wfs_url = wfs_url;
    this.wfs_layer_name = wfs_layer_name;
    this.wms_url = wms_url;
    this.wms_layer_name = wms_layer_name;
    this.wmts_url = wmts_url;
    this.wmts_layer_name = wmts_layer_name;
    this.sort_order = sort_order;
  }

  public static default(customer: CustomerModel): BasemapModel {
    return new BasemapModel(
      0,
      "",
      "empty",
      customer,
      true,
      false,
      "",
      "",
      "",
      "",
      "",
      "",
      0
    );
  }

  public static create(props: IBasemap): BasemapModel {
    return new BasemapModel(
      props.id as number | undefined,
      props.name as string,
      props.type as BasemapType,
      props.customer as CustomerModel,
      props.show_layer as boolean,
      props.use_transparancy as boolean,
      props.wfs_url as string,
      props.wfs_layer_name as string,
      props.wms_url as string,
      props.wms_layer_name as string,
      props.wmts_url as string,
      props.wmts_layer_name as string,
      props.sort_order as number
    );
  }

  public set_value(
    field: string,
    value: string | number | BasemapType | boolean
  ): void {
    if (
      field === "id" &&
      typeof value === "number" &&
      (this.id === 0 || this.id === undefined)
    ) {
      this.id = value;
    } else if (field === "name" && typeof value === "string") {
      this.name = value;
    } else if (field === "type" && isBasemapType(value)) {
      this.type = value;
    } else if (field === "show_layer" && typeof value === "boolean") {
      this.show_layer = value;
    } else if (field === "use_transparancy" && typeof value === "boolean") {
      this.use_transparancy = value;
    } else if (field === "wfs_url" && typeof value === "string") {
      this.wfs_url = value;
    } else if (field === "wfs_layer_name" && typeof value === "string") {
      this.wfs_layer_name = value;
    } else if (field === "wms_url" && typeof value === "string") {
      this.wms_url = value;
    } else if (field === "wms_layer_name" && typeof value === "string") {
      this.wms_layer_name = value;
    } else if (field === "wmts_url" && typeof value === "string") {
      this.wmts_url = value;
    } else if (field === "wmts_layer_name" && typeof value === "string") {
      this.wmts_layer_name = value;
    } else if (field === "sort_order") {
      this.sort_order = value as any;
    }
  }
}
