import * as React from "react";
import "./OauthSection.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faUserSecret } from "@fortawesome/free-solid-svg-icons";

import { CustomerModel } from "../../models/customer";
import { OauthConnector, OauthModel } from "../../models/oauth";
import { Translate } from "../../core/translate";
import { Bus } from "../../core/bus";
import { AjaxResponse, AjaxResponseT, isAjaxOk } from "../../core/ajaxresponse";
import _ from "lodash";

export interface OauthSectionState {
  oauth: OauthModel;
  callbackUrlFull: string;
  callbackUrlPath: string;
  saved: boolean;
}

export interface OauthSectionProps {
  customer: CustomerModel;
  translate: Translate;
}

export class OauthSection extends React.Component<
  OauthSectionProps,
  OauthSectionState
> {
  constructor(props: OauthSectionProps) {
    super(props);
    this.state = {
      oauth: OauthModel.default(this.props.customer),
      callbackUrlFull: "",
      callbackUrlPath: "",
      saved: true,
    };
    this.cbGetOauth = this.cbGetOauth.bind(this);
    this.handleOauthChange = this.handleOauthChange.bind(this);
    this.cbOauthCreated = this.cbOauthCreated.bind(this);
    this.cbSaveNew = this.cbSaveNew.bind(this);
    this.showSecret = this.showSecret.bind(this);
    this.generateApiKey = this.generateApiKey.bind(this);
    this.cbGenerateApiKey = this.cbGenerateApiKey.bind(this);
    this.save = _.debounce(this.save, 100).bind(this);
  }

  componentDidMount(): void {
    const bus = Bus.getInstance();
    const response = new AjaxResponse(bus, [], this.cbGetOauth);
    //Please note: we are calling 'list' but it is actually a 1:1 relationship
    //             Why a list? evt we would need to be able to add/revoke keys
    new OauthConnector(this.props.customer.id as number).list(response);
  }

  private cbGetOauth(response: AjaxResponseT): void {
    const trNotSaved = this.props.translate.go(
      "Not saved, check the input fields"
    );

    if (isAjaxOk(response)) {
      if ("oauth" in response.data) {
        let newOauth: Record<string, unknown>;

        if (Array.isArray(response.data.oauth)) {
          if (response.data.oauth.length > 0) {
            newOauth = response.data.oauth[0];
          } else {
            return;
          }
        } else {
          newOauth = response.data.oauth as Record<string, unknown>;
        }

        this.setState((prevState) => {
          const oauth = prevState.oauth;
          for (const k in newOauth) {
            if (Object.prototype.hasOwnProperty.call(newOauth, k)) {
              oauth.set_value(k, newOauth[k]);
            }
          }
          if (newOauth.api_id !== undefined) {
            // set_value does not work here, as we want to avoid that it can be changed by the user
            oauth.api_id = newOauth.api_id as string | undefined;
          }
          const callbackUrlFull = newOauth["callback_url_full"] as string;
          const callbackUrlPath = newOauth["callback_url_path"] as string;
          return {
            ...prevState,
            oauth,
            callbackUrlFull,
            callbackUrlPath,
            saved: true,
          };
        });
      }
    } else {
      console.error(response);
      if (this.state.saved) {
        this.setState((oldState) => {
          return Object.assign(oldState, { saved: false });
        });
      }
    }
  }

  private cbOauthCreated(response: AjaxResponseT): void {
    const trNotSaved = this.props.translate.go(
      "Not saved, check the input fields"
    );
    if (isAjaxOk(response)) {
      this.setState((oldState) => {
        return Object.assign(oldState, { saved: true });
      });
    } else {
      console.error(response);
      if (this.state.saved) {
        alert(trNotSaved);
        this.setState((oldState) => {
          return Object.assign(oldState, { saved: false });
        });
      }
    }
  }

  handleOauthChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState((oldState) => {
      const field = event?.target?.name;
      const oauth = oldState.oauth;
      oauth.set_value(field, event?.target?.value); // this skips api_id
      const newOauth = this.state.oauth.id === 0;
      if (!newOauth) {
        this.save(oauth);
      }
      return { ...oldState };
    });
  }

  private save(oauth: OauthModel) {
    const bus = Bus.getInstance();
    const response = new AjaxResponse(bus, [], this.cbGetOauth);
    if (this.props.customer.id !== undefined) {
      new OauthConnector(this.props.customer.id).save(
        oauth.pk,
        oauth.props,
        response
      );
    }
  }

  private cbGenerateApiKey(response: AjaxResponseT): void {
    const trWriteItDown = this.props.translate.go("New api_id and api_key");
    const trApiKey = this.props.translate.go("API Key");
    const trApiKeyId = this.props.translate.go("API Key ID");
    if (isAjaxOk(response)) {
      if ("api_id" in response.data && "api_key" in response.data) {
        alert(trWriteItDown);
        prompt(trApiKeyId, response.data.api_id as string);
        prompt(trApiKey, response.data.api_key as string);
        this.setState((oldState) => {
          const oauth = oldState.oauth;
          oauth.api_id = response.data.api_id as string;
          return { ...oldState, oauth };
        });
      }
    }
  }

  generateApiKey(_event: React.MouseEvent<HTMLButtonElement>): void {
    _event.preventDefault();
    const trAreYouSure = this.props.translate.go(
      "Confirm new api_key generation"
    );
    const trThisWillImpactUsers = this.props.translate.go("api_key generated");

    const bus = Bus.getInstance();
    const response = new AjaxResponse(bus, [], this.cbGenerateApiKey);
    if (confirm(`${trAreYouSure}\n${trThisWillImpactUsers}`)) {
      if (
        this.props.customer.id !== undefined &&
        this.state.oauth.id !== undefined
      ) {
        this.state.oauth.generateNewApiKey(response);
      }
    }
  }

  showSecret(_event: React.MouseEvent<HTMLButtonElement>): void {
    _event.preventDefault();
    const el = document.getElementById("secret") as HTMLInputElement;
    el.type = "text";
    window.setTimeout(() => {
      el.type = "password";
    }, 300);
  }

  cbSaveNew(_event: React.MouseEvent<HTMLButtonElement>): void {
    if (this.props.customer.id !== undefined) {
      const bus = Bus.getInstance();
      const response = new AjaxResponse(bus, [], this.cbOauthCreated);
      new OauthConnector(this.props.customer.id).create(
        this.state.oauth.props,
        response
      );
    }
  }

  render(): JSX.Element {
    const translate = this.props.translate;

    const trOauth = translate.go("Oauth");
    const trApiKey = translate.go("API Key");
    const trClientId = translate.go("ClientID");
    const trSecret = translate.go("Secret");
    const trAuthorisationUrl = translate.go("Authorization URL");
    const trAccessTokenUrl = translate.go("Access Token URL");
    const trResourceUrl = translate.go("Resource URL");
    const trScope = translate.go("Scope");
    const trApiKeyId = translate.go("API Key ID");
    const trSave = translate.go("Save");
    const trGenerateApiKey = translate.go("Generate new api_key");
    const trNotSaved = translate.go("Not saved, check the input fields");
    const required = "*";

    if (this.state.oauth === null) {
      return <div></div>;
    }
    const apiKeySettings = () => {
      const newMode = this.state.oauth.id === 0; //||this.props.customer.id === undefined;
      if (newMode) {
        return (
          <div>
            <button onClick={this.cbSaveNew}>{trSave}</button>
          </div>
        );
      } else {
        return (
          <div>
            <label htmlFor={"api_id"} className={"fieldLabel"}>
              {trApiKeyId}
            </label>
            <input
              type={"text"}
              name={"api_id"}
              id={"api_id"}
              disabled={true}
              value={this.state.oauth.api_id}
              placeholder={"aafkaaacac"}
              className={"fieldInput"}
            />
            <br />
            <button onClick={this.generateApiKey}>
              <FontAwesomeIcon icon={faUserSecret} />&nbsp;
              {trGenerateApiKey}
            </button>
          </div>
        );
      }
    };
    const didSave = this.state.saved ? "saved" : "notsaved";
    return (
      <div className={didSave}>
        <h3 className={"subTitle"}>{trOauth}</h3>
        <p>
          <label>Callback URL full</label>
          <input disabled={true} value={this.state.callbackUrlFull} />
          <br />
          <label>Callback URL path</label>
          <input disabled={true} value={this.state.callbackUrlPath} />
        </p>
        <form>
          <input type={"hidden"} name={"id"} value={this.state.oauth.id} />
          <label htmlFor={"client_id"} className={"fieldLabel"}>
            {trClientId}
            {required}
          </label>
          <input
            type={"text"}
            name={"client_id"}
            id={"client_id"}
            value={this.state.oauth.client_id}
            placeholder={"1233456"}
            onChange={this.handleOauthChange}
          />
          <br />

          <span>
            <label htmlFor={"secret"} className={"fieldLabel"}>
              {trSecret}
              {required}
              <button onClick={this.showSecret}>
                <FontAwesomeIcon icon={faEye} />
              </button>
            </label>
          </span>
          <input
            type={"password"}
            name={"secret"}
            id={"secret"}
            value={this.state.oauth.secret}
            placeholder={"secret"}
            onChange={this.handleOauthChange}
          />
          <br />

          <label htmlFor={"auth_url"} className={"fieldLabel"}>
            {trAuthorisationUrl}
            {required}
          </label>
          <input
            type={"text"}
            name={"auth_url"}
            id={"auth_url"}
            value={this.state.oauth.auth_url}
            placeholder={"https://<auth0-tenant-domain>/authorize"}
            onChange={this.handleOauthChange}
            className={"fieldInput"}
          />
          <br />

          <label htmlFor={"accesstoken_url"} className={"fieldLabel"}>
            {trAccessTokenUrl}
            {required}
          </label>
          <input
            type={"text"}
            name={"accesstoken_url"}
            id={"accesstoken_url"}
            value={this.state.oauth.accesstoken_url}
            placeholder={"https://<auth0-tenant-domain>/oauth/token"}
            onChange={this.handleOauthChange}
            className={"fieldInput"}
          />
          <br />

          <label htmlFor={"resource_url"} className={"fieldLabel"}>
            {trResourceUrl}
            {required}
          </label>
          <input
            type={"text"}
            name={"resource_url"}
            id={"resource_url"}
            value={this.state.oauth.resource_url}
            placeholder={"https://example.com/resource.json"}
            onChange={this.handleOauthChange}
            className={"fieldInput"}
          />
          <br />

          <label htmlFor={"scope"} className={"fieldLabel"}>
            {trScope}
          </label>
          <input
            type={"text"}
            name={"scope"}
            id={"scope"}
            value={this.state.oauth.scope}
            placeholder={"optional"}
            onChange={this.handleOauthChange}
            className={"fieldInput"}
          />
          <div className={"show-if-not-saved"}>{trNotSaved}</div>
        </form>
        <h3 className={"subTitle"}>{trApiKey}</h3>
        <form>{apiKeySettings()}</form>
      </div>
    );
  }
}
