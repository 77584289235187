import * as ReactDOM from "react-dom";
import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";

import {
  CustomerConnector,
  CustomerModel,
  ICustomer,
} from "../../models/customer";
import { Bus } from "../../core/bus";
import { AjaxResponse, AjaxResponseT, isAjaxOk } from "../../core/ajaxresponse";
import { PlanViewer } from "../planviewer";
import { CustomerSection } from "./CustomerSection";
import { ViewerSection } from "./ViewersSection";
import { CustomerList } from "./CustomerList";
import { OauthSection } from "./OauthSection";
import { Translate } from "../../core/translate";
import { BasemapSection } from "./BasemapSection";

export interface CustomerDetailState {
  customer: CustomerModel | null;
}
export interface CustomerDetailProps {
  customerId: number; // 0 means: let's create a new one
  customerList: CustomerList;
  planviewer: PlanViewer;
  translate: Translate;
}

export class CustomerDetail extends React.Component<
  CustomerDetailProps,
  CustomerDetailState
> {
  constructor(props: CustomerDetailProps) {
    super(props);
    this.state = { customer: null };
    this.cbGetCustomer = this.cbGetCustomer.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  componentDidMount(): void {
    const bus = Bus.getInstance();
    if (this.props.customerId === 0) {
      const customer = CustomerModel.default();
      this.setState((prevState) => {
        return {
          ...prevState,
          customer,
        };
      });
    } else {
      const response = new AjaxResponse(bus, [], this.cbGetCustomer);
      new CustomerConnector().read(this.props.customerId, response);
    }
  }

  private cbGetCustomer(response: AjaxResponseT): void {
    if (isAjaxOk(response)) {
      if ("customer" in response.data) {
        const rcustomer = response.data.customer as ICustomer;
        const customer: CustomerModel = CustomerModel.create(rcustomer);
        if (customer.id === this.props.customerId) {
          // it was an update: save the state
          this.setState((prevState) => {
            return {
              ...prevState,
              customer,
            };
          });
        } else if (customer.id && this.props.customerId === 0) {
          // we got an new one. let's reload
          this.changeCustomer(customer);
        }
      }
    }
  }

  private handleGoBack(_event: React.MouseEvent<HTMLButtonElement>): void {
    // @TODO: There is problably a better way (this.props.customerList.loadCustomers()?)
    ReactDOM.render(
      <CustomerList
        planviewer={this.props.planviewer}
        translate={this.props.translate}
      />,
      document.getElementById("adminpanel")
    );
  }

  changeCustomer(customer: CustomerModel): void {
    if (customer.id !== undefined && customer.id !== 0) {
      this.props.customerList.changeCustomer(customer.id);
    }
  }

  render(): JSX.Element {
    const translate = this.props.translate;

    const trCustomer = translate.go("Customer");
    const trLeave = translate.go("Go back");
    if (this.state.customer === null) {
      return <div></div>;
    } else if (this.props.customerId === 0) {
      return (
        <div>
          <button onClick={this.handleGoBack}>
            <FontAwesomeIcon icon={faList} />&nbsp;
            {trLeave}
          </button>
          <h2 className={"title"}>
            {trCustomer + " " + this.state.customer.name}
          </h2>
          <CustomerSection
            customer={this.state.customer}
            customerDetail={this}
            translate={this.props.translate}
          />
        </div>
      );
    } else {
      return (
        <div>
          <button onClick={this.handleGoBack}>
            <FontAwesomeIcon icon={faList} />&nbsp;
            {trLeave}
          </button>
          <h2 className={"title"}>
            {trCustomer + " " + this.state.customer.name}
          </h2>
          <CustomerSection
            customer={this.state.customer}
            customerDetail={this}
            translate={this.props.translate}
          />
          <OauthSection
            customer={this.state.customer}
            translate={this.props.translate}
          />
          <BasemapSection
            customer={this.state.customer}
            planviewer={this.props.planviewer}
            translate={this.props.translate}
          />
          <ViewerSection
            customer={this.state.customer}
            planviewer={this.props.planviewer}
            translate={this.props.translate}
          />
        </div>
      );
    }
  }
}
