import * as ReactDOM from "react-dom";
import React from "react";

import { Bus } from "./core/bus";
import { AdminPanel } from "./app/components/AdminPanel";
import { PlanViewer } from "./app/planviewer";
import { Translate } from "./core/translate";
import { loadDictionary } from "./app/translations";

import "./styles/mapviewer/adminpanel.scss";

function addAdminPanel(element: Element) {
  const bus = Bus.getInstance();
  const planviewer = new PlanViewer(bus);
  const translate = new Translate(loadDictionary(), "Dutch");
  ReactDOM.render(<AdminPanel
    planviewer={planviewer}
    translate={translate}
  />, element);
}

(window as any).addAdminPanel = addAdminPanel;
