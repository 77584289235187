import * as React from "react";

import { PlanViewer, PlanViewerViewerDetails } from "../planviewer";
import { CustomerModel } from "../../models/customer";
import { Translate } from "../../core/translate";
import { Config } from "../config";

const OUTLINE_MODE = "outline";
const VIEWER_MODE = "viewer";

const formatTimestamp = (fullDatetime: string): string => {
  const datetime = fullDatetime.split(".")[0];
  return datetime.replace(/T/, " ");
};

export interface ViewerSectionState {
  viewers: PlanViewerViewerDetails[];
}

export interface ViewerSectionProps {
  customer: CustomerModel;
  planviewer: PlanViewer;
  translate: Translate;
}

export class ViewerSection extends React.Component<
  ViewerSectionProps,
  ViewerSectionState
> {
  constructor(props: ViewerSectionProps) {
    super(props);
    this.state = { viewers: [] };

    this.cbViewerClick = this.cbViewerClick.bind(this);
  }

  componentDidMount(): void {
    this.props.customer
      .listViewers()
      .then((viewers: PlanViewerViewerDetails[] | undefined) => {
        if (viewers !== undefined) {
          this.setState((oldState) => {
            return { ...oldState, viewers };
          });
        }
      });
  }

  cbViewerClick(event: React.MouseEvent<HTMLButtonElement>): void {
    const identifier = (event.target as any).dataset.identifier;
    const mode = (event.target as any).dataset.mode;
    const url = Config.embed_url(mode, identifier);
    window.open(url, "_blank");
  }

  render(): JSX.Element {
    const translate = this.props.translate;

    const trViewers = translate.go("Viewers list");
    const trMap = translate.go("Map");
    const trName = translate.go("Name");
    const trViewer = translate.go("Viewer");
    const trEditOutline = translate.go("Edit outline");
    const trCreatedAt = translate.go("Created at");
    const trUpdatedAt = translate.go("Updated at");

    return (
      <div>
        <h3 className={"subTitle"}>{trViewers}</h3>
        <table>
          <thead>
            <tr>
              <th>{trName}</th>
              <th colSpan={2}>{trMap}</th>
              <th>{trCreatedAt}</th>
              <th>{trUpdatedAt}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.viewers.map((viewer) => (
              <tr key={viewer.identifier}>
                <td>{viewer.name}</td>
                <td>
                  <button
                    data-identifier={viewer.identifier}
                    data-mode={VIEWER_MODE}
                    onClick={this.cbViewerClick}
                  >
                    {trViewer}
                  </button>
                </td>
                <td>
                  <button
                    data-identifier={viewer.identifier}
                    data-mode={OUTLINE_MODE}
                    onClick={this.cbViewerClick}
                  >
                    {trEditOutline}
                  </button>
                </td>
                <td>{formatTimestamp(viewer.created_at || "")}</td>
                <td>{formatTimestamp(viewer.updated_at || "")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
