import { CustomerModel } from "../../models/customer";
import { Translate } from "../../core/translate";
import { PlanViewer } from "../planviewer";
import * as React from "react";
import { Bus } from "../../core/bus";
import {
  AjaxResponse,
  AjaxResponseT,
  isAjaxError,
  isAjaxOk,
} from "../../core/ajaxresponse";
import { BasemapConnector, BasemapModel, IBasemap } from "../../models/basemap";
import _ from "lodash";
import { BasemapDetail } from "./BasemapDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface Props {
  customer: CustomerModel;
  translate: Translate;
  // TODO check if this prop is needed
  planviewer: PlanViewer;
}

interface State {
  basemaps: BasemapModel[];
}

export class BasemapSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { basemaps: [] };

    this.loadBasemaps = this.loadBasemaps.bind(this);
    this.cbGetBasemaps = this.cbGetBasemaps.bind(this);
    this.addBasemap = this.addBasemap.bind(this);
    this.cbAddBasemap = this.cbAddBasemap.bind(this);
  }

  componentDidMount(): void {
    this.loadBasemaps();
  }

  loadBasemaps(): void {
    const bus = Bus.getInstance();
    const response = new AjaxResponse(bus, [], this.cbGetBasemaps);
    new BasemapConnector(this.props.customer.id!).list(response);
  }

  private cbGetBasemaps(response: AjaxResponseT): void {
    if (isAjaxOk(response)) {
      const basemaps: BasemapModel[] = [];

      _.forEach(response.data, (basemap: any) => {
        basemaps.push(
          new BasemapModel(
            basemap.id,
            basemap.name,
            basemap.type,
            this.props.customer,
            basemap.show_layer,
            basemap.use_transparancy,
            basemap.wfs_url,
            basemap.wfs_layer_name,
            basemap.wms_url,
            basemap.wms_layer_name,
            basemap.wmts_url,
            basemap.wmts_layer_name,
            basemap.sort_order
          )
        );
      });

      this.setState({ basemaps });
    }
  }

  private addBasemap(againBecauseDuplicateName = false) {
    const translate = this.props.translate;
    const trPleaseEnterUniqueName = translate.go("Please enter a unique name.");
    const trNameAlreadyUsed = translate.go(
      "Another basemap with that name already exists."
    );

    let namePromptMsg = trPleaseEnterUniqueName;
    if (againBecauseDuplicateName) {
      namePromptMsg = trNameAlreadyUsed + "\n" + namePromptMsg;
    }

    const bus = Bus.getInstance();
    const response = new AjaxResponse(bus, [], this.cbAddBasemap);
    const basemap = BasemapModel.default(this.props.customer);
    const name = window.prompt(namePromptMsg);
    if (name !== null) {
      basemap.set_value("name", name);
      const connector = new BasemapConnector(this.props.customer.id!);
      connector.create(basemap.props, response);
    }
  }

  private addBasemapAgainBecauseDuplicateName() {
    this.addBasemap(true);
  }

  private cbAddBasemap(response: AjaxResponseT) {
    if (isAjaxOk(response)) {
      const props = (response.data as unknown) as IBasemap;
      props["customer"] = this.props.customer;
      const basemap = BasemapModel.create(props);
      this.setState((prevState) => {
        return {
          ...prevState,
          basemaps: [...prevState.basemaps, basemap],
        };
      });
    } else if (isAjaxError(response)) {
      const isDuplicateName =
        response.content?.indexOf("Duplicate customer and name") !== -1;

      if (isDuplicateName) {
        this.addBasemapAgainBecauseDuplicateName();
      }
    }
  }

  private onDelete(basemap: BasemapModel) {
    this.setState((prevState) => {
      const basemaps = [...prevState.basemaps];
      const index = basemaps.findIndex((obj) => basemap.id === obj.id);
      if (index !== -1) {
        basemaps.splice(index, 1);
      }
      return { ...prevState, basemaps };
    });
  }

  render() {
    const translate = this.props.translate;
    const trBasemaps = translate.go("Basemaps");
    const trAddBasemap = translate.go("Add Basemap");

    return (
      <div>
        <h3 className={"subTitle"}>{trBasemaps}</h3>
        {this.state.basemaps.map((basemap) => {
          return (
            <div>
              <BasemapDetail
                key={basemap.id}
                basemap={basemap}
                translate={this.props.translate}
                onDelete={() => this.onDelete(basemap)}
              />
              <br />
            </div>
          );
        })}
        <button onClick={(e) => this.addBasemap()}>
          <FontAwesomeIcon icon={faPlus} />
          &nbsp;
          {trAddBasemap}
        </button>
      </div>
    );
  }
}
